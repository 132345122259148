<template>
  <div class="dialog-body" v-loading="loading">
    <span>Dữ liệu bao gồm:</span>
    <ul class="dialog-list">
      <li>Số lượng lớp học: {{ exportCertificateInfo.totalClassroom }}</li>
      <li>Tổng số chứng chỉ: {{ exportCertificateInfo.totalCertificates }}</li>
      <li>Số chứng chỉ đã từng xuất: {{ exportCertificateInfo.totalCertificateExported }}</li>
    </ul>
    <span style="word-break: break-word">Bạn muốn xuất tất cả chứng chỉ (bao gồm cả các chứng chỉ đã từng xuất) hay chỉ xuất các chứng chỉ mới?</span>
    <div class="dialog-footer">
      <el-button style="background: #E6E6E6" @click="exportCertificates('all')" :disabled="loadingExport">
        Xuất tất cả
      </el-button>
      <el-button style="background: #4C0192; color: white" @click="exportCertificates('new')" :disabled="loadingExport">
        Xuất chứng chỉ mới
      </el-button>
    </div>
  </div>
</template>

<script>

import {CertificateModel, generateCertificates} from "../model/CertificateModel";
import {EXPORT_CERTIFICATE, SHOW_EXPORT_CERTIFICATE} from "../../../../core/services/store/course/classes.module";

export default {
  name: "DialogConfirmExportCertificate",
  props: {
    payload: {
      type: Object,
      required: true,
      default: {}
    }
  },
  data() {
    return {
      loading: true,
      exportCertificateInfo: {
        totalClassroom: 0,
        totalCertificates: 0,
        totalCertificateExported: 0,
        dataExportAll: [],
        dataExport: [],
      },
      loadingExport: false,
    }
  },
  mounted() {
    this.getInfoCertificates()
  },
  methods: {
    async getInfoCertificates() {
      try {
        const res = await this.$store.dispatch(SHOW_EXPORT_CERTIFICATE, this.payload);
        if (!res.error) {
          const { totalClass, totalDataExportAll, totalDataExportDone, dataExporDataExportAll, dataExportStudentDone } = res.data || {};
          this.exportCertificateInfo = {
            totalClassroom: totalClass ?? 0,
            totalCertificates: totalDataExportAll ?? 0,
            totalCertificateExported: totalDataExportDone ?? 0,
            dataExportAll: this.mapToCertificateModel(dataExporDataExportAll) ?? [],
            dataExport: this.mapToCertificateModel(dataExporDataExportAll?.filter(item =>
                !dataExportStudentDone?.some(doneItem => doneItem.user_id === item.user_id)
            )) ?? [],
          };
        }
      } finally {
        this.loading = false;
      }
    },

    mapToCertificateModel(data = []) {
      return data.map(item => new CertificateModel(item?.user_name, item?.date, item?.type_certificate, item));
    },

    exportCertificateUpdate(listStudent) {
      const listUser = listStudent.map(item => {
        return item.data
      })
      this.$store.dispatch(EXPORT_CERTIFICATE, {listUser: listUser}).then((res) => {
        console.log(res.message, 'export certificate success')
      })
    },

    async exportCertificates(type) {
      this.loadingExport = true;
      const data = type === 'all' ? this.exportCertificateInfo?.dataExportAll : this.exportCertificateInfo?.dataExport;

      if (data?.length > 0) {
        this.$emit('closeDialog');
        await generateCertificates(data).finally(() => (this.loadingExport = false));
        this.exportCertificateUpdate(data)
        this.$message.success('Xuất chứng chỉ thành công !');
      } else {
        this.$message.warning('Không tìm thấy danh sách học viên nhận được chứng chỉ !');
        this.loadingExport = false;
      }
    },
  }
}
</script>

<style scoped>
.dialog-body {
  margin-top: -20px;
}

.dialog-list {
  padding: 10px 30px;
}

.dialog-footer {
  margin-top: 15px;
  display: flex;
  width: 100%;
  justify-content: end;
  flex-direction: row;
  gap: 10px;
}
</style>