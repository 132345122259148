<template>
  <div class="card card-custom gutter-b" v-loading="loading">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Sửa lỗi dạy học</h3>
      </div>
      <div class="card-title">
        <div class="dropdown dropdown-inline mr-2">
          <button type="button" @click="exportOption"
            class="btn btn-light-primary font-weight-bolder">
            <span class="svg-icon svg-icon-md">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                    fill="#000000" opacity="0.3"></path>
                  <path
                    d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                    fill="#000000"></path>
                </g>
              </svg>
            </span>Xuất excel
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <label>Trung tâm</label>
            <el-select v-model="query.center_ids" multiple filterable collapse-tags placeholder="Chọn trung tâm"
              @change="changeCenter">
              <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <label>Chi nhánh</label>
            <el-select v-model="query.branch_ids" multiple filterable collapse-tags placeholder="Chọn chi nhánh"
              @change="changeBranch">
              <el-option v-for="item in branches" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <label>Khóa học</label>
            <el-select v-model="query.course_ids" multiple filterable collapse-tags placeholder="Chọn khoá học"
              @change="changeCourse">
              <el-option v-for="item in courses" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <label>Lớp học</label>
            <el-select v-model="query.class_ids" multiple filterable collapse-tags placeholder="Chọn lớp học">
              <el-option v-for="item in class_ids" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <label>Nhóm giảng viên</label>
            <el-select v-model="query.group_id" clearable filterable collapse-tags placeholder="Chọn nhóm giảng viên"
                       @change="changeTeacher">
              <el-option v-for="item in listTeacherGroup" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <label>Giảng viên</label>
            <el-select v-model="query.teacher_ids" multiple filterable collapse-tags placeholder="Chọn tên giảng viên"
              @change="changeTeacher">
              <el-option v-for="item in teachers" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <label>Thời gian học</label>
            <date-picker v-model="query.date_range" type="date" format="DD-MM-YYYY" value-type="YYYY-MM-DD" range
              placeholder="Chọn ngày tạo"></date-picker>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <div class="d-flex justify-content-start mt-2">
              <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2" @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
              <button v-else type="button" disabled
                class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive overflow-auto mt-3">
          <table class="table table-hover table-bordered table-vertical-center b-table"
            style="text-align: center">
            <thead>
              <tr>
                <th scope="col" class="min-w-100px">Lớp học</th>
                <th scope="col" class="min-w-100px">Khóa học</th>
                <th scope="col" class="min-w-100px">Giảng viên</th>
                <th scope="col" class="min-w-100px">Tình trạng lớp</th>

                <!-- 1 : Sắp khai giảng,  2: Đang học -->
                <th scope="col" class="min-w-100px">Chi nhánh</th>
                <th scope="col" class="min-w-120px">Ngày khai giảng</th>
                <th scope="col" class="min-w-120px">Ngày dự kiến kết thúc</th>
                <th scope="col" class="min-w-120px">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <ClassErrorEach v-for="item in classErrorList" @getApi-class="getApiClass" :classError="item"
                :key="item.id"></ClassErrorEach>
              <tr v-if="classErrorList.length === 0 && !loading">
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="position-absolute" style="right:40px">
          Tổng số bản ghi: <b style="color:#3699FF">{{ total }}</b>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'"
        :pageLinkClass="'page-link'" :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'"
        :prev-class="'page-link'" :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>

    <!--  Modal Export Option -->
    <el-dialog v-if="is_dialog_option" :show-close="false" :visible.sync="is_dialog_option" width="30%">
      <div slot="title" style="display: flex; width: 100%; justify-content: space-between">
        <h3>Export dữ liệu</h3>
        <i class="el-icon-close" style="cursor: pointer" @click="closeDialog"></i>
      </div>
      <div v-if="!isCertificate">
        <strong>Vui lòng chọn dữ liệu để tải xuống</strong>
        <div class="d-flex flex-column mt-5">
          <el-radio v-model="type_export" :label="1">Danh sách học viên</el-radio>
          <el-radio class="mt-2" v-model="type_export" :label="2">Danh sách lượt học</el-radio>
          <el-radio class="mt-2" v-model="type_export" :label="3">Bằng khen, chứng chỉ</el-radio>
        </div>
        <div slot="footer" class="dialog-footer d-flex justify-content-end">
          <el-button @click="is_dialog_option = false"
                     style="background: #E6E6E6 !important; color: #0a0a0a !important;">
            Hủy
          </el-button>
          <el-button :disabled="is_disable_export"
                     style="background: #4c0192 !important; color: white !important;" @click="exportData">
            <i v-if="is_disable_export" class="el-icon-loading mr-2"></i>
            <i v-else class="el-icon-download mr-2"></i>Export
          </el-button>
        </div>
      </div>
      <DialogConfirmExportCertificate :payload="query" @closeDialog="closeDialog" v-else/>
    </el-dialog>
  </div>
</template>
<script>

import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import ClassErrorEach from "./components/ClassErrorEach.vue";
import { GET_CENTERS } from "../../../core/services/store/center/centers.module";
import { GET_BRANCHES } from "../../../core/services/store/center/branch.module";
import {
    EXPORT_ERROR_CLASSROOM,
    GET_TEACHERS_OR_TUTORS,
    EXPORT_ERROR_CLASSROOM_STUDENT,
    CLASS_ERROR_LIST,
    CLASS_FILTER
} from "../../../core/services/store/course/classes.module";
import { GET_COURSES_LIST } from "../../../core/services/store/course/course.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {exportExcel, HEADER_ROW_EXPORT_ERROR_LIST_STUDY_TURN, HEADER_ROW_EXPORT_ERROR_LIST_STUDENT} from "../../../core/filters";
import DialogConfirmExportCertificate from "./components/DialogConfirmExportCertificate.vue";
import {GET_TEACHER_GROUPS} from "@/core/services/store/teacher/teacher.module";


export default {
  components: {
    DialogConfirmExportCertificate,
    ClassErrorEach,
    DatePicker
  },

  name: "manageClass",
  data() {
    return {
      query: {
        center_ids: [],
        branch_ids: [],
        course_ids: [],
        class_name: '',
        teacher_ids: [],
        class_ids: [],
        date_range: [],
        group_id: ''
      },
      listCertificate: [],
      oneClassList: {},
      classErrorList: [],
      centers: [],
      center_ids : [],
      branches: [],
      teachers: [],
      courses: [],
      class_ids: [],
      is_disable_search: false,
      last_page: 1,
      page: 1,
      total: 0,
      is_disable_export: false,
      is_dialog_option: false,
      type_export : 1,
      loading: true,
      isCertificate: false,
      listTeacherGroup: []
    };
  },

  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý dạy học", route: ""},
      {title: "Sửa lỗi dạy học"},
    ]);
    await this.getCenters();
    this.getBranches();
    this.getTeachers();
    this.getCourses();
    this.getClasses();
    this.callApiGetList();
    this.getTeacherGroups();
  },
  computed: {},

  methods: {
    changeCenter() {
      this.getBranches();
      this.getCourses();
      this.getTeachers();
      this.getClasses();
    },
    changeBranch() {
      this.getClasses();
    },
    changeCourse() {
      this.getClasses();
    },
    changeTeacher() {
      this.getClasses();
    },
    exportOption() {
      this.is_dialog_option = true;
    },
    exportData() {
      this.is_disable_export = true;
      switch (this.type_export) {
        case 1:
          this.exportStudentList();
          break
        case 2:
          this.exportStudySession();
          break;
        case 3:
          this.isCertificate = true;
          break
        default:
          return;
      }
    },
    closeDialog() {
      this.is_dialog_option = false
      this.isCertificate = false
      this.is_disable_export = false
      this.type_export = 1
    },
    async exportStudentList() {
      let data_student;
      await this.$store.dispatch(EXPORT_ERROR_CLASSROOM_STUDENT, {
        ...this.query,
      }).then((res) => {
        data_student = res.data;
      }).finally(() => {
        this.is_disable_export = false;
        this.is_dialog_option = false;
      });
      exportExcel(HEADER_ROW_EXPORT_ERROR_LIST_STUDENT,data_student, "Danh sách học viên.xlsx", false)
    },
    async exportStudySession() {
      let data_study;
      await this.$store.dispatch(EXPORT_ERROR_CLASSROOM, {
        ...this.query,
      }).then((res) => {
        data_study = res.data;
      }).finally(() => {
        this.is_disable_export = false;
        this.is_dialog_option = false;
      });
      exportExcel(HEADER_ROW_EXPORT_ERROR_LIST_STUDY_TURN,data_study, "Danh sách lượt học.xlsx", false)
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        name: 'manage-error',
        query: {
          page: this.page,
          ...this.query
        }
      })
      this.callApiGetList()
    },
    getClassErrors() {
      this.$store.dispatch(CLASS_ERROR_LIST).then((res) => {
        if (!res.error) {
          this.classErrorList = res.data?.data ?? res.data;
          this.last_page = res.last_page ?? res.pagination.last_page
        }
      });
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'manage-error',
        query: {
          page: this.page,
          ...this.query
        }
      })
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    callApiGetList() {
      let payload = {};
      payload = {...this.query, page: this.page}
      if(payload.center_ids.length == 0) {
        payload = {...payload,center_ids:this.center_ids}
      }
      this.$store.dispatch(CLASS_ERROR_LIST,payload).then((res) => {
        if (!res.error) {
          this.loading = false
          this.classErrorList = res.data?.data ?? res.data;
          this.last_page = res.last_page ?? res.pagination.last_page;
          this.total = res.total ?? res.pagination.total;
          this.is_disable_search = false;
        }
      });
    },
    getClasses() {
      this.query.class_ids = [];
      let payload = {};
      payload = {...this.query, page: this.page}
      if(payload.center_ids.length == 0) {
        payload = {...payload,center_ids:this.center_ids}
      }
      this.$store.dispatch(CLASS_FILTER,payload).then((res) => {
        if (!res.error) {
          this.class_ids = res.data;
        }
      });
    },
    getApiClass(nameClass) {
      this.oneClassList = this.classList[0];
    },

    // lấy API tất cả các trung tâm
    getCenters() {
      return this.$store.dispatch(GET_CENTERS,{is_edutalk:'1'}).then((res) => {
        if (!res.error) {
          for (let dataKey in res.data.data) {
            this.center_ids.push(res.data.data[dataKey].id);
            this.centers.push({
              id: res.data.data[dataKey].id,
              name: res.data.data[dataKey].name,
            });
          }
        }
      });
    },

    // lấy API tất cả các giảng viên
    getTeachers() {
      return this.$store
        .dispatch(GET_TEACHERS_OR_TUTORS, {
          limit: 10000,
          is_teacher: 1,
          center_ids: this.query.center_ids.length > 0 ? this.query.center_ids : this.center_ids
        })
        .then((res) => {
          if (!res.error) {
            this.teachers = res.data;
          }
        })
        .catch((e) => {
          console.log(e, "error");
        });
    },

    // lấy API tất cả các chi nhánh
    getBranches() {
      return this.$store
        .dispatch(GET_BRANCHES, {
          limit: 10000,
          status: 1,
          center_ids: this.query.center_ids.length > 0 ? this.query.center_ids : this.center_ids
        })
        .then((res) => {
          if (!res.error) {
            this.branches = res.data.data;
          }
        });
    },

    // lấy API tất cả các khoá học
    getCourses() {
      return this.$store
        .dispatch(GET_COURSES_LIST, {
          limit: 10000,
          status: 1,
          center_ids: this.query.center_ids.length > 0 ? this.query.center_ids : this.center_ids
        })
        .then((res) => {
          if (!res.error) {
            this.courses = res.data;
            // this.setCacheTheLoad()
          }
        })
        .catch((e) => {
          console.log(e, "error");
        });
    },
    getTeacherGroups(){
      this.$store.dispatch(GET_TEACHER_GROUPS).then((data)=>{
        this.listTeacherGroup = data.data
        this.listTeacherGroup.splice(0,0, {id: 0, name: '(Không có nhóm)'})
      })
    },
  },
};
</script>
<style lang="scss" scoped></style>
