import JSZip from "jszip";
import jsPDF from "jspdf";
import {saveAs} from "file-saver";
import {templateCertificates} from "../../../../common/define";

export class CertificateModel {
    constructor(name, date, type, record) {
        this.name = name ?? '';
        this.date = date ?? '';
        this.type = type;
        this.data = record
    }
}

export const generateCertificates = async (records) => {
    const zip = new JSZip();

    for (const student of records) {
        let i = 0
        await generateCertificate(student, zip)
    }

    const zipBlob = await zip.generateAsync({type: "blob"});
    saveAs(zipBlob, `Certificates.zip`);
}

export const generateCertificate = async (certificate, zip) => {
    const typeTemplate = templateCertificates.find(template => template.id === certificate.type)
    const templateName = typeTemplate.fontName;
    const nameCertificate = certificate.name
    const fontFile = typeTemplate.fontFile
    if (!typeTemplate) return;
    const pdf = new jsPDF({
        orientation: typeTemplate.type,
        unit: "px",
        format: [typeTemplate.height, typeTemplate.width]
    });
    const FlamingoFont = await getFont(fontFile)
    const url = require(`@/assets/img/${typeTemplate.image}`)
    const image = await loadImage(url);
    pdf.addImage(image, "JPEG", 0, 0, typeTemplate.width, typeTemplate.height);
    pdf.addFileToVFS(fontFile, FlamingoFont);
    pdf.addFont(fontFile, templateName, "normal");
    pdf.setFont(templateName);
    const pageWidth = pdf.internal.pageSize.width;
    pdf.setFontSize(typeTemplate.fontSizeName);
    const text = await capitalizeText(nameCertificate)
    pdf.text(text, pageWidth / 2, typeTemplate.topName, {align: "center"});
    pdf.setFontSize(typeTemplate.fontSizeDate);
    pdf.setTextColor(typeTemplate.color === 2 ? 255 : 0, typeTemplate.color === 2 ? 255 : 0, typeTemplate.color === 2 ? 255 : 0);
    pdf.text(certificate.date, typeTemplate.leftDate, typeTemplate.topDate);
    const pdfBlob = pdf.output("blob");
    zip.file(`${text}-${typeTemplate.templateName}.pdf`, pdfBlob);
}

export const getFont = async (fontFile) => {
    const response = await fetch(`/fonts/${fontFile}`);
    const fontData = await response.arrayBuffer();
    return btoa(new Uint8Array(fontData).reduce((data, byte) => data + String.fromCharCode(byte), ""))
}

export const loadImage = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL("image/png"));
        };
        img.onerror = (err) => reject(err);
        img.src = url;
    });
}

export const capitalizeText = async (text) => {
    if (!text) return null
    return text
        .toLowerCase()
        .split(/\s+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}